import { useEffect } from 'react';
import PropTypes from 'prop-types';

const MetaTags = ({ title, description }) => {
  useEffect(() => {
    // Update title
    document.title = title;

    // Update the essential meta tags that Discord uses
    const updateMetaTag = (selector, content) => {
      const element = document.querySelector(selector);
      if (element) {
        element.setAttribute('content', content);
      }
    };

    // Update only the essential tags
    updateMetaTag('meta[property="og:title"]', title);
    updateMetaTag('meta[property="og:description"]', description);
    updateMetaTag('meta[name="description"]', description);

  }, [title, description]);

  return null;
};

MetaTags.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default MetaTags; 