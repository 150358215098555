import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';

function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo-link">
          <h1 className="header-title">FinData</h1>
        </Link>
        <SearchBar />
      </div>
    </header>
  );
}

export default Header;
