export const getScoreColor = (score) => {
  if (score >= 90) return '#4169E1'; // Royal Blue for Ice Cold
  if (score >= 80) return '#6495ED'; // Cornflower Blue for Cold
  if (score >= 65) return '#87CEEB'; // Sky Blue for Chilly
  if (score >= 50) return '#B8B8B8'; // Light Gray for Neutral
  if (score >= 35) return '#FFB347'; // Pastel Orange for Warm
  if (score >= 20) return '#FF6B6B'; // Light Red for Hot
  return '#FF4040'; // Coral Red for On Fire
};

export const getStatusLabel = (score) => {
  if (score >= 90) return 'Strong Buy';
  if (score >= 80) return 'Good Buy';
  if (score >= 65) return 'Buy';
  if (score >= 50) return 'Neutral';
  if (score >= 35) return 'Sell';
  if (score >= 20) return 'Good Sell';
  return 'Strong Sell';
};

export const formatPrice = (price) => {
  // For prices less than 1 cent, show up to 8 decimal places
  if (price < 0.01) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 8,
      maximumFractionDigits: 8
    }).format(price);
  }
  
  // For prices less than $1, show up to 4 decimal places
  if (price < 1) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 4,
      maximumFractionDigits: 4
    }).format(price);
  }
  
  // For regular prices, show 2 decimal places
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
};
