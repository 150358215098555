import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getScoreColor, getStatusLabel } from '../utils/helpers';
import { 
  FaSnowflake, 
  FaThermometerEmpty, 
  FaThermometerQuarter,
  FaThermometerHalf, 
  FaThermometerThreeQuarters, 
  FaThermometerFull, 
  FaFire 
} from 'react-icons/fa';
import CryptoSnapshotClient from '../services/CryptoSnapshotClient';

const client = new CryptoSnapshotClient();

const getStatusIcon = (score) => {
  if (score >= 90) return <FaSnowflake className="status-icon ice-cold-icon" />;
  if (score >= 80) return <FaThermometerEmpty className="status-icon cold-icon" />;
  if (score >= 65) return <FaThermometerQuarter className="status-icon chilly-icon" />;
  if (score >= 50) return <FaThermometerHalf className="status-icon neutral-icon" />;
  if (score >= 35) return <FaThermometerThreeQuarters className="status-icon warm-icon" />;
  if (score >= 20) return <FaThermometerFull className="status-icon hot-icon" />;
  return <FaFire className="status-icon on-fire-icon animate-flame" />;
};

const CryptoTable = () => {
  const navigate = useNavigate();
  const [cryptoData, setCryptoData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [pagination, setPagination] = useState({
    currentPage: 0,
    size: 50,
    totalElements: 0,
    totalPages: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const params = {
          page: pagination.currentPage,
          size: pagination.size,
          sort: 'lastMarketCapRank,asc'
        };
        
        const response = await client.getCryptoSnapshots(params);

        if (response && response.content) {
          setCryptoData(response.content);
          setPagination(prev => ({
            ...prev,
            currentPage: response.pageNumber,
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages
          }));
        } else if (Array.isArray(response)) {
          setCryptoData(response);
          setPagination(prev => ({
            ...prev,
            totalElements: response.length,
            totalPages: Math.ceil(response.length / pagination.size)
          }));
        } else {
          throw new Error('Invalid data format received from the server');
        }
      } catch (err) {
        console.error('Detailed error:', err);
        let errorMessage;
        if (err.message === 'Failed to fetch') {
          errorMessage = 'Unable to connect to the server. Please check your internet connection.';
        } else if (err.status === 404) {
          errorMessage = 'The requested data could not be found.';
        } else if (err.status >= 500) {
          errorMessage = 'The server encountered an error. Please try again later.';
        } else {
          errorMessage = `${err.message || 'An error occurred while loading the data. Please try again later.'}`;
        }
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [pagination.currentPage, pagination.size]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'ascending' 
        ? 'descending' 
        : 'ascending'
    }));
  };

  const getSortedData = () => {
    if (!cryptoData || !sortConfig.key) return cryptoData;
    
    return [...cryptoData].sort((a, b) => {
      if (sortConfig.key === 'name') {
        const compareResult = a.name.localeCompare(b.name);
        return sortConfig.direction === 'ascending' ? compareResult : -compareResult;
      }
      if (sortConfig.key === 'score') {
        const scoreA = a.cryptoScoreCard.total;
        const scoreB = b.cryptoScoreCard.total;
        const compareResult = scoreA - scoreB;
        return sortConfig.direction === 'ascending' ? compareResult : -compareResult;
      }
      return 0;
    });
  };

  const handlePageChange = (newPage) => {
    setPagination(prev => ({
      ...prev,
      currentPage: newPage
    }));
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPagination(prev => ({
      ...prev,
      currentPage: 0,
      size: newSize
    }));
  };

  const renderPagination = () => {
    if (!pagination.totalPages || pagination.totalPages <= 1) {
      return null;
    }

    const pages = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(0, pagination.currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(pagination.totalPages - 1, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(0, endPage - maxVisiblePages + 1);
    }

    if (startPage > 0) {
      pages.push(
        <button 
          key="first" 
          onClick={() => handlePageChange(0)}
          className="pagination-button"
        >
          1
        </button>
      );
      if (startPage > 1) pages.push(<span key="ellipsis1" className="pagination-ellipsis">...</span>);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-button ${pagination.currentPage === i ? 'active' : ''}`}
        >
          {i + 1}
        </button>
      );
    }

    if (endPage < pagination.totalPages - 1) {
      if (endPage < pagination.totalPages - 2) pages.push(<span key="ellipsis2" className="pagination-ellipsis">...</span>);
      pages.push(
        <button
          key="last"
          onClick={() => handlePageChange(pagination.totalPages - 1)}
          className="pagination-button"
        >
          {pagination.totalPages}
        </button>
      );
    }

    return pages;
  };

  const handleRowClick = (ticker) => {
    navigate(`/crypto/${ticker.toLowerCase()}`);
  };

  if (error) {
    return (
      <div className="error-container">
        <div className="error-message">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-content">
          <div className="loading-spinner"></div>
          <p>Loading crypto data...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="crypto-table">
      <div className="table-header">
        <div 
          className="column-name" 
          onClick={() => handleSort('name')} 
          style={{ cursor: 'pointer' }}
        >
          Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
        </div>
        <div 
          className="column-score" 
          onClick={() => handleSort('score')} 
          style={{ cursor: 'pointer' }}
        >
          Score {sortConfig.key === 'score' && (sortConfig.direction === 'ascending' ? '↑' : '↓')}
        </div>
        <div className="column-status">Status</div>
      </div>

      {getSortedData().map((crypto) => (
        <div 
          key={crypto.ticker} 
          className="crypto-row"
          onClick={() => handleRowClick(crypto.ticker)}
        >
          <div className="column-name">
            <div className="crypto-name-wrapper">
              {crypto.name}
              <span className="crypto-symbol">{crypto.ticker}</span>
            </div>
          </div>
          <div className="column-score">
            <div className="score-circle" style={{
              backgroundColor: `${getScoreColor(crypto.cryptoScoreCard.total)}`
            }}>
              {crypto.cryptoScoreCard.total}
            </div>
          </div>
          <div className="column-status">
            <span className="status-wrapper">
              {getStatusIcon(crypto.cryptoScoreCard.total)}
              {getStatusLabel(crypto.cryptoScoreCard.total)}
            </span>
          </div>
        </div>
      ))}

      {cryptoData.length > 0 && (
        <div className="table-footer">
          <div className="page-size-selector">
            <label htmlFor="pageSize">Items per page:</label>
            <select
              id="pageSize"
              value={pagination.size}
              onChange={handlePageSizeChange}
              className="page-size-select"
            >
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          
          <div className="pagination-container">
            <button
              className="pagination-button"
              onClick={() => handlePageChange(Math.max(0, pagination.currentPage - 1))}
              disabled={pagination.currentPage === 0}
            >
              ←
            </button>
            {renderPagination()}
            <button
              className="pagination-button"
              onClick={() => handlePageChange(Math.min(pagination.totalPages - 1, pagination.currentPage + 1))}
              disabled={pagination.currentPage === pagination.totalPages - 1}
            >
              →
            </button>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="loading-container">
          <div className="loading-content">
            <div className="loading-spinner"></div>
            <p>Loading crypto data...</p>
          </div>
        </div>
      )}

      {error && (
        <div className="error-container">
          <div className="error-message">
            <p>{error}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CryptoTable;
