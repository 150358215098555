import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CryptoSnapshotClient from '../services/CryptoSnapshotClient';
import TradingViewChart from './TradingViewChart';
import { FaArrowDown, FaArrowUp, FaSnowflake, FaThermometerEmpty, FaThermometerQuarter, FaThermometerHalf, FaThermometerThreeQuarters, FaThermometerFull, FaFire } from 'react-icons/fa';
import MetaTags from './MetaTags';
import { getStatusLabel, formatPrice } from '../utils/helpers';

const getStatusIcon = (score) => {
  if (score >= 90) return <FaSnowflake className="status-icon ice-cold-icon" />;
  if (score >= 80) return <FaThermometerEmpty className="status-icon cold-icon" />;
  if (score >= 65) return <FaThermometerQuarter className="status-icon chilly-icon" />;
  if (score >= 50) return <FaThermometerHalf className="status-icon neutral-icon" />;
  if (score >= 35) return <FaThermometerThreeQuarters className="status-icon warm-icon" />;
  if (score >= 20) return <FaThermometerFull className="status-icon hot-icon" />;
  return <FaFire className="status-icon on-fire-icon animate-flame" />;
};

const CryptoDetail = () => {
  const { ticker } = useParams();
  const [crypto, setCrypto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCryptoDetail = async () => {
      try {
        const client = new CryptoSnapshotClient();
        const cryptoDetail = await client.getCryptoByTicker(ticker.toUpperCase());
        
        if (!cryptoDetail) {
          throw new Error(`Unable to find data for ${ticker.toUpperCase()}`);
        }
        
        setCrypto(cryptoDetail);
      } catch (err) {
        setError(err.message || 'An unexpected error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCryptoDetail();
  }, [ticker]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return (
    <div className="error-container">
      <div className="error-message">
        <h2>Oops!</h2>
        <p>{error}</p>
      </div>
    </div>
  );
  if (!crypto) return (
    <div className="error-container">
      <div className="error-message">
        <h2>Not Found</h2>
        <p>Cryptocurrency data not found</p>
      </div>
    </div>
  );

  const getPercentageChange = (current, comparison) => {
    const change = ((current - comparison) / comparison) * 100;
    return {
      value: Math.abs(change).toFixed(2),
      isPositive: change > 0
    };
  };

  const ma30Change = getPercentageChange(crypto.close, crypto.ema30);
  const ma100Change = getPercentageChange(crypto.close, crypto.ema100);
  const ma200Change = getPercentageChange(crypto.close, crypto.ema200);

  const metaTitle = `${crypto.name} (${crypto.ticker}) is a ${getStatusLabel(crypto.cryptoScoreCard.total)}`;
  const metaDescription = `Score: ${crypto.cryptoScoreCard.total} - 30 Day: ${formatPrice(crypto.ema30)} | 100 Day: ${formatPrice(crypto.ema100)} | 200 Day: ${formatPrice(crypto.ema200)}`;

  return (
    <>
      <MetaTags
        title={metaTitle}
        description={metaDescription}
      />
      <div className="crypto-detail-container">
        <div className="detail-content">
          <div className="metrics-panel">
            <div className="metrics-section">
              <div className="header-group">
                <div className="name-ticker">
                  <h1>{crypto.name}</h1>
                  <div className="ticker">{crypto.ticker}</div>
                </div>
                <div className="price-status">
                  <div className="price-display">
                    <span className="metric-value">{formatPrice(crypto.close)}</span>
                  </div>
                  <div className="status-wrapper">
                    {getStatusIcon(crypto.cryptoScoreCard.total)}
                    <span className="verdict">
                      {getStatusLabel(crypto.cryptoScoreCard.total)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="metrics-section">
              <h2>Moving Averages</h2>
              <div className="ma-list">
                <div className="ma-item">
                  <div className="ma-header">
                    <span className="ma-label">30 Day</span>
                    <span className="ma-price">{formatPrice(crypto.ema30)}</span>
                  </div>
                  <div className={`ma-change ${ma30Change.isPositive ? 'positive' : 'negative'}`}>
                    {ma30Change.isPositive ? <FaArrowUp /> : <FaArrowDown />}
                    {ma30Change.value}%
                  </div>
                </div>

                <div className="ma-item">
                  <div className="ma-header">
                    <span className="ma-label">100 Day</span>
                    <span className="ma-price">{formatPrice(crypto.ema100)}</span>
                  </div>
                  <div className={`ma-change ${ma100Change.isPositive ? 'positive' : 'negative'}`}>
                    {ma100Change.isPositive ? <FaArrowUp /> : <FaArrowDown />}
                    {ma100Change.value}%
                  </div>
                </div>

                <div className="ma-item">
                  <div className="ma-header">
                    <span className="ma-label">200 Day</span>
                    <span className="ma-price">{formatPrice(crypto.ema200)}</span>
                  </div>
                  <div className={`ma-change ${ma200Change.isPositive ? 'positive' : 'negative'}`}>
                    {ma200Change.isPositive ? <FaArrowUp /> : <FaArrowDown />}
                    {ma200Change.value}%
                  </div>
                </div>
              </div>
            </div>

            <div className="metrics-section">
              <h2>RSI</h2>
              <div className="ma-item">
                <div className="ma-header">
                  <span className="ma-label">14 Day</span>
                  <span className="ma-price">{crypto.rsi.toFixed(1)}</span>
                </div>
                <div className={`ma-change ${crypto.rsi > 70 ? 'negative' : crypto.rsi < 30 ? 'positive' : ''}`}>
                  {crypto.rsi > 70 ? 'Overbought' : crypto.rsi < 30 ? 'Oversold' : 'Neutral'}
                </div>
              </div>
            </div>
          </div>

          <div className="chart-panel">
            <TradingViewChart symbol={crypto.lookupTicker} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoDetail;