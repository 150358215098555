import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaTimes } from 'react-icons/fa';
import CryptoSnapshotClient from '../services/CryptoSnapshotClient';

const client = new CryptoSnapshotClient();

const SearchBar = () => {
    const [searchText, setSearchText] = useState('');
    const [results, setResults] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const searchRef = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsOpen(false);
                if (!searchText) {
                    setIsExpanded(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [searchText]);

    useEffect(() => {
        if (isExpanded && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isExpanded]);

    useEffect(() => {
        const searchCrypto = async () => {
            if (searchText.length < 2) {
                setResults([]);
                return;
            }

            setLoading(true);
            try {
                const data = await client.searchCryptocurrencies(searchText);
                setResults(data);
                setIsOpen(true);
            } catch (error) {
                console.error('Search error:', error);
                setResults([]);
            } finally {
                setLoading(false);
            }
        };

        const timeoutId = setTimeout(searchCrypto, 300);
        return () => clearTimeout(timeoutId);
    }, [searchText]);

    const handleSelect = (ticker) => {
        setSearchText('');
        setIsOpen(false);
        setIsExpanded(false);
        navigate(`/crypto/${ticker.toLowerCase()}`);
    };

    const handleSearchClick = () => {
        setIsExpanded(true);
    };

    const handleClose = () => {
        setSearchText('');
        setIsExpanded(false);
        setIsOpen(false);
    };

    if (!isExpanded) {
        return (
            <button className="search-icon-button" onClick={handleSearchClick}>
                <FaSearch />
            </button>
        );
    }

    return (
        <div className="search-container expanded" ref={searchRef}>
            <div className="search-input-wrapper">
                <input
                    ref={inputRef}
                    type="text"
                    className="search-input"
                    placeholder="Search cryptocurrencies..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onFocus={() => searchText.length >= 2 && setIsOpen(true)}
                />
                <button className="search-close-button" onClick={handleClose}>
                    <FaTimes />
                </button>
            </div>
            {loading && <div className="search-loading">Loading...</div>}
            {isOpen && results.length > 0 && (
                <div className="search-results">
                    {results.map((crypto) => (
                        <div
                            key={crypto.ticker}
                            className="search-result-item"
                            onClick={() => handleSelect(crypto.ticker)}
                        >
                            <span className="result-name">{crypto.name}</span>
                            <span className="result-ticker">{crypto.ticker}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchBar; 