class CryptoSnapshotClient {
    constructor(baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080') {
        this.baseUrl = baseUrl?.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
    }

    async getCryptoSnapshots(params = {}) {
        try {
            if (!this.baseUrl) {
                throw new Error('Base URL is not defined');
            }

            const url = new URL(`${this.baseUrl}/crypto-snapshots/by-group`);
            
            const defaultParams = {
                page: 0,
                size: 100,
                sort: 'lastMarketCapRank,asc',
                ...params
            };

            Object.entries(defaultParams).forEach(([key, value]) => {
                if (value !== undefined) {
                    url.searchParams.append(key, value);
                }
            });

            const response = await fetch(url.toString());
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching crypto snapshots:', error);
            throw error;
        }
    }

    async getCryptoByTicker(ticker) {
        try {
            if (!this.baseUrl) {
                throw new Error('Base URL is not defined');
            }

            const url = new URL(`${this.baseUrl}/crypto-snapshots/ticker/${ticker}`);
            const response = await fetch(url.toString());
            
            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error(`Cryptocurrency ${ticker} not found`);
                }
                throw new Error('Unable to fetch cryptocurrency data. Please try again later.');
            }

            const text = await response.text();
            if (!text) {
                throw new Error('No data received from server');
            }

            try {
                return JSON.parse(text);
            } catch (parseError) {
                throw new Error('Invalid data received from server');
            }
        } catch (error) {
            console.error('Error fetching crypto by ticker:', error);
            throw error;
        }
    }

    async searchCryptocurrencies(searchText) {
        try {
            if (!this.baseUrl) {
                throw new Error('Base URL is not defined');
            }

            const url = new URL(`${this.baseUrl}/cryptocurrencies/search`);
            const response = await fetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: searchText }),
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error searching cryptocurrencies:', error);
            throw error;
        }
    }
}

export default CryptoSnapshotClient;
