import React, { useEffect, useRef, useMemo } from 'react';

const SCRIPT_ID = 'crypto_mkt_script';
const CONTAINER_ID = 'crypto_mkt';

const TradingViewChart = ({ symbol }) => {
    const tradingViewRef = useRef(null);

    const defaultOptions = useMemo(() => ({
        "autosize": true,
        "symbol": symbol,
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "light",
        "style": "1",
        "locale": "en",
        "toolbar_bg": "#f1f3f6",
        "enable_publishing": false,
        "hide_top_toolbar": true,
        "allow_symbol_change": false,
        "save_image": false,
        "container_id": CONTAINER_ID,
        "studies": [
            {
                "id": "MAExp@tv-basicstudies",
                "version": 60,
                "inputs": {
                    "length": 30
                }
            },
            {
                "id": "MAExp@tv-basicstudies",
                "version": 60,
                "inputs": {
                    "length": 100
                }
            },
            {
                "id": "MAExp@tv-basicstudies",
                "version": 60,
                "inputs": {
                    "length": 200
                }
            },
        ],
    }), [symbol]);

    useEffect(() => {
        const canUseDOM = () => {
            return (
                typeof window !== 'undefined' &&
                window.document &&
                window.document.createElement
            );
        };

        const getScriptElement = () => {
            return document.getElementById(SCRIPT_ID);
        };

        const scriptExists = () => {
            return getScriptElement() !== null;
        };

        const initWidget = () => {
            if (typeof window.TradingView === 'undefined') {
                return;
            }
            new window.TradingView.widget(defaultOptions);
        };

        const appendScript = (onload) => {
            if (!canUseDOM()) {
                onload();
                return;
            }

            if (scriptExists()) {
                if (typeof window.TradingView === 'undefined') {
                    const script = getScriptElement();
                    const oldOnload = script.onload;
                    script.onload = () => {
                        oldOnload && oldOnload();
                        onload();
                    };
                    return;
                }
                onload();
                return;
            }

            const script = document.createElement('script');
            script.id = SCRIPT_ID;
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://s3.tradingview.com/tv.js';
            script.onload = onload;
            tradingViewRef.current.appendChild(script);
        };

        const timeoutId = setTimeout(() => {
            appendScript(initWidget);
        }, 300);

        return () => {
            clearTimeout(timeoutId);
            const script = getScriptElement();
            if (script) {
                script.remove();
            }
        };
    }, [defaultOptions]); // Only depends on defaultOptions now

    return (
        <div
            id={CONTAINER_ID}
            ref={tradingViewRef}
            className="tradingview-widget-container"
            style={{ height: '400px' }}
        >
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default TradingViewChart;